import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import AppLogo from '../../assets/images/app-logo.svg';
import CaretDownIcon from '../../assets/images/caret-down-grey.svg';
import MOHLogo from '../../assets/images/MOH-logo.png';
import LogoutIcon from '../../assets/images/power-switch.svg';
import { fetchCountryName, logoutRequest, setIsKenya } from '../../store/user/actions';
import {
  firstNameSelector,
  isKenyaSelector,
  lastNameSelector,
  roleSelector,
  rolesSelector
} from '../../store/user/selectors';

import APPCONSTANTS, { ROLE_LABELS } from '../../constants/appConstants';
import sessionStorageServices from '../../global/sessionStorageServices';
import useUserAdmin from '../../hooks/userAdmin';
import styles from './Header.module.scss';

export default function Header() {
  const dispatch = useDispatch();
  const firstName: string = useSelector(firstNameSelector) || '';
  const lastName: string = useSelector(lastNameSelector) || '';
  const role = useSelector(roleSelector);
  const roleDetail = useSelector(rolesSelector);
  const isKenya = useSelector(isKenyaSelector);
  const { HomeUrl } = useUserAdmin();

  const handleLogout = () => {
    dispatch(logoutRequest());
    sessionStorageServices.deleteItem(APPCONSTANTS.COUNTRY_NAME);
    sessionStorageServices.deleteItem(APPCONSTANTS.REGION_ID);
    sessionStorageServices.deleteItem(APPCONSTANTS.IS_KENYA);
  };

  const roleDisplayName = roleDetail?.displayName;
  return (
    <div>
      <nav
        className={`navbar navbar-expand navbar-light bg-light ps-sm-3dot125 ps-1 pe-sm-1dot5 pe-1 ${styles.appNavbar}`}
      >
        <Link to={HomeUrl} className={styles.brand}>
          {isKenya && (
            <img
              src={MOHLogo}
              alt='Ministry of Health'
              height='58'
              onClick={() => {
                sessionStorageServices.deleteItem(APPCONSTANTS.IS_KENYA);
                dispatch(setIsKenya(false));
              }}
            />
          )}
          <img
            src={AppLogo}
            alt='logo'
            width={200}
            height={45}
            onClick={() => {
              sessionStorageServices.deleteItem(APPCONSTANTS.COUNTRY_NAME);
              dispatch(fetchCountryName());
            }}
          />
        </Link>
        <div className={`nav-item dropdown ms-auto`}>
          <div
            className={`nav-link dropdown-toggle light d-flex align-items-center ${styles.userOptions}`}
            id='navbarScrollingDropdown'
            role='button'
            data-bs-toggle='dropdown'
            aria-expanded='false'
          >
            <div className={`d-flex align-items-center justify-content-center me-dot5 fw-bold ${styles.userLogo}`}>
              {`${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()}
            </div>
            <div>
              <div className='highlight-text text-capitalize'>
                {firstName || lastName ? `${firstName} ${lastName}` : ''}
              </div>
              <div className='subtle-small-text'>{roleDisplayName || ROLE_LABELS[role]}</div>
            </div>
            <img src={CaretDownIcon} alt='' className='ms-dot625' />
          </div>
          <ul
            className={`dropdown-menu dropdown-menu-end highlight-small-text pt-0 ${styles.navbarDropdown} flex-column`}
            aria-labelledby='navbarScrollingDropdown'
          >
            {/* {APPCONSTANTS.MANAGE_USER_ROLES_ACCESS.includes((role)) && (
              <li className='w-100'>
                <NavLink to={PROTECTED_ROUTES.manageUserRoles} exact={true}>
                  <div className={`dropdown-item pointer d-flex align-items-center ${styles.navbarDropdownItem}`}>
                    <div className={`${styles.iconWrapper} d-flex align-items-center justify-content-center`}>
                      <img src={UserRoleIcon} alt='' height={16} />
                    </div>
                    Manage User Roles
                  </div>
                </NavLink>
              </li>
            )} */}
            <li className='w-100'>
              <div
                className={`dropdown-item pointer d-flex align-items-center ${styles.navbarDropdownItem}`}
                onClick={() => handleLogout()}
              >
                <div className={`${styles.iconWrapper} d-flex align-items-center justify-content-center`}>
                  <img src={LogoutIcon} alt='' width={14} height={15} />
                </div>
                Logout
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

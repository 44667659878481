import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { countryIdSelector, getAppTypeSelector } from '../store/user/selectors';
import { APP_TYPE } from '../constants/appConstants';

const COMMUNITY = {
  isCommunity: true
};

const NON_COMMUNITY = {
  isCommunity: false
};

/**
 * Custom hook to get the label base on the appType.
 * @return {string}
 */
const useAppTypeConfigs = () => {
  const appTypesFromUser = useSelector(getAppTypeSelector);
  const userCountry = useSelector(countryIdSelector);
  const appTypes = useMemo(() => {
    // use app types from user object for super admin
    if (appTypesFromUser && appTypesFromUser.length) {
      return appTypesFromUser;
    } else if (userCountry && userCountry.appTypes && userCountry.appTypes.length) {
      // use app types from country for other admins
      return userCountry.appTypes;
    }
    return [];
  }, [appTypesFromUser, userCountry]);

  return useMemo(
    () =>
      Array.isArray(appTypes) && appTypes.includes(APP_TYPE.NON_COMMUNITY)
        ? { ...NON_COMMUNITY, appTypes }
        : { ...COMMUNITY, appTypes },
    [appTypes]
  );
};
export default useAppTypeConfigs;

import * as REPORT_ACTION_TYPES from '../report/actionTypes';

import { ReportActions, IReportState } from './types';

const initialState: IReportState = {
  total: 0,
  screenedList: [],
  enrolledPatientList: [],
  duplicatePatientList: [],
  assessmentList: [],
  userPerformanceList: [],
  loading: false,
  error: null
};

const reportReducer = (state: IReportState = initialState, action: ReportActions) => {
  switch (action.type) {
    case REPORT_ACTION_TYPES.FETCH_SCREENED_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        total: action.payload.totalCount || 0,
        screenedList: action.payload.screenedList || []
      };
    case REPORT_ACTION_TYPES.FETCH_ASSESSMENT_LIST_REQUEST:
    case REPORT_ACTION_TYPES.FETCH_ENROLLED_PATIENT_REQUEST:
    case REPORT_ACTION_TYPES.FETCH_SCREENED_PATIENT_LIST_REQUEST:
    case REPORT_ACTION_TYPES.FETCH_USER_PERFORMANCE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case REPORT_ACTION_TYPES.FETCH_ASSESSMENT_LIST_FAILURE:
    case REPORT_ACTION_TYPES.FETCH_ENROLLED_PATIENT_FAILURE:
    case REPORT_ACTION_TYPES.FETCH_SCREENED_PATIENT_FAILURE:
    case REPORT_ACTION_TYPES.FETCH_USER_PERFORMANCE_LIST_FAILURE:
      return {
        ...state,
        loading: false
      };
    case REPORT_ACTION_TYPES.FETCH_ENROLLED_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        total: action.payload.totalCount || 0,
        enrolledPatientList: action.payload.enrolledPatientList || []
      };
    case REPORT_ACTION_TYPES.FETCH_ASSESSMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        total: action.payload.totalCount || 0,
        assessmentList: action.payload.assessmentList || []
      };
    case REPORT_ACTION_TYPES.FETCH_USER_PERFORMANCE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        total: action.payload.totalCount || 0,
        userPerformanceList: action.payload.userPerformanceList || []
      };
    default:
      return state;
  }
};

export default reportReducer;

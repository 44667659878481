import { useCallback, useEffect, useMemo, useState } from 'react';
import { PROTECTED_ROUTES } from '../../constants/route';
import { useParams, Link } from 'react-router-dom';
import sessionStorageServices from '../../global/sessionStorageServices';
import APPCONSTANTS from '../../constants/appConstants';
import WarningIcon from '../../assets/images/warning.svg';
import toastCenter from '../../utils/toastCenter';
import Modal from '../../components/modal/ModalForm';
import Loader from '../../components/loader/Loader';
import { isMOHReportPending } from '../../services/reportAPI';
import { useSelector } from 'react-redux';
import { isKenyaSelector, reportPrivilegesSelector, roleSelector } from '../../store/user/selectors';
import { getIsUserFacilityReportAdmin, isKHISLastDateExceeded } from '../../utils/commonUtils';
import styles from './ReportList.module.scss';
import useAppTypeConfigs from '../../hooks/appTypeBasedConfigs';

interface IMatchParams {
  regionId: string;
  regionName: string;
  siteId: string;
  siteName: string;
}

const ReportList = (props: IMatchParams): React.ReactElement => {
  const { siteId, siteName, regionId, regionName } = useParams<IMatchParams>();

  const role: any = useSelector(roleSelector);
  const isKenya = useSelector(isKenyaSelector);

  const { isCommunity } = useAppTypeConfigs();

  const [showAlertModal, setShowAlertModal] = useState({
    message: '',
    show: false
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    toastCenter.dismissAllToast();
    const MOHStatusPayload = {
      key: getLastMonthKey().monthYear,
      reportType: 'MOH-740',
      siteId
    };
    if (getIsUserFacilityReportAdmin(role) && isKenya) {
      IsMOHReportPushedToKHIS(MOHStatusPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const countryId = sessionStorageServices.getItem(APPCONSTANTS.REGION_ID);
  const reportPrivileges = useSelector(reportPrivilegesSelector);

  const communityReports = useMemo(
    () => [
      {
        name: 'hf1',
        label: 'HF1',
        hasSubText: false
      },
      {
        name: 'hf2',
        label: 'HF2',
        hasSubText: false
      },
      {
        name: 'hf3',
        label: 'HF3',
        hasSubText: false
      },
      {
        name: 'hf4',
        label: 'HF4',
        hasSubText: false
      }
    ],
    []
  );

  const nonCommunityReports = useMemo(
    () => [
      // {
      //   name: 'Presc',
      //   label: APPCONSTANTS.REPORT_LIST_LABEL.PRESCRIPTION_MEDICATION,
      //   route: PROTECTED_ROUTES.prescriptionReport,
      //   hasAccess: true,
      //   hasSubText: false
      // },
      {
        name: 'ScreenedReport',
        label: APPCONSTANTS.REPORT_LIST_LABEL.SCREENED_PATIENTS,
        route: PROTECTED_ROUTES.screenedReport,
        hasAccess: true,
        hasSubText: false
      },
      {
        name: 'EnrolledPatientReport',
        label: APPCONSTANTS.REPORT_LIST_LABEL.ENROLLED_PATIENTS,
        route: PROTECTED_ROUTES.enrolledPatientReport,
        hasAccess: true,
        hasSubText: false
      },
      {
        name: 'AssessmentReport',
        label: APPCONSTANTS.REPORT_LIST_LABEL.ASSESSMENT_REPORT,
        route: PROTECTED_ROUTES.assessmentReport,
        hasAccess: true,
        hasSubText: false
      }
      // {
      //   name: 'UserPerformance',
      //   label: APPCONSTANTS.REPORT_LIST_LABEL.USER_PERFORMANCE,
      //   route: PROTECTED_ROUTES.userPerformance,
      //   hasAccess: true,
      //   hasSubText: false
      // },
      // {
      //   name: 'PatientStatusReport',
      //   label: APPCONSTANTS.REPORT_LIST_LABEL.PATIENT_STATUS_REPORT,
      //   route: PROTECTED_ROUTES.patientStatusReport,
      //   hasAccess: true,
      //   hasSubText: false
      // }
    ],
    []
  );
  if (isKenya) {
    nonCommunityReports.unshift(
      {
        name: 'Daily',
        label: APPCONSTANTS.REPORT_LIST_LABEL.DAILY_ACTIVITY_REGISTER,
        route: PROTECTED_ROUTES.dailyReport,
        hasAccess: true,
        hasSubText: true
      },
      {
        name: 'Permanent',
        label: APPCONSTANTS.REPORT_LIST_LABEL.PERMANANT_REGISTER,
        route: PROTECTED_ROUTES.permanentReport,
        hasAccess: true,
        hasSubText: true
      },
      {
        name: 'MOH',
        label: APPCONSTANTS.REPORT_LIST_LABEL.MONTHLY_SUMMARY,
        route: PROTECTED_ROUTES.MOHReport,
        hasAccess: true,
        hasSubText: true
      }
    );
  }

  const getLastMonthKey = () => {
    const now = new Date();
    const newdate = new Date(now.setMonth(now.getMonth() - 1));
    const key = {
      month: '',
      monthYear: ''
    };
    key.month = APPCONSTANTS.MONTH[newdate.getMonth()].name;
    key.monthYear = APPCONSTANTS.MONTH[newdate.getMonth()].label + new Date().getFullYear();
    return key;
  };

  const IsMOHReportPushedToKHIS = useCallback(async (MOHStatusPayload) => {
    try {
      setLoading(true);
      const { data } = await isMOHReportPending(MOHStatusPayload);
      setLoading(false);
      if (!data?.entity?.is_data_pushedTo_KHIS && !isKHISLastDateExceeded()) {
        setShowAlertModal({
          message: `This site has pending reports of ${getLastMonthKey().month} to be sent to KHIS`,
          show: true
        });
      }
    } catch (error: any) {
      setLoading(false);
      setShowAlertModal({ message: error.message || APPCONSTANTS.MOH_REPORT_STATUS_ERROR, show: true });
    }
  }, []);

  const alertModalRender = () => {
    return (
      <div className={styles.alertItems}>
        <img className={styles.alertModalIcon} src={WarningIcon} alt='Alert' />
        <br />
        <p className={styles.alertModalText}>{showAlertModal.message}</p>
        <br />
        <button
          className={styles.closeButton}
          onClick={() => {
            setShowAlertModal({
              message: '',
              show: false
            });
          }}
        >
          OK
        </button>
      </div>
    );
  };

  const parsedData = useMemo(() => {
    if (isCommunity) {
      return communityReports
        .filter(({ name }) => !reportPrivileges?.length || reportPrivileges?.includes(name))
        .map(({ label, name, hasSubText }) => ({
          title: label,
          detailRoute: PROTECTED_ROUTES.HFReport.replace(':regionId', regionId)
            .replace(':regionName', regionName)
            .replace(':siteId', siteId)
            .replace(':siteName', siteName)
            .replace(':reportType', label.slice(0, 3).toLowerCase() + (label.slice(4) ? '-' + label.slice(4) : '')),
          _id: countryId,
          icon: require(`../../assets/images/${name}.svg`).default,
          name: sessionStorageServices.getItem(APPCONSTANTS.COUNTRY_NAME),
          hasSubText
        }));
    } else {
      return nonCommunityReports
        .filter(({ hasAccess }) => hasAccess)
        .map(({ label, route, name, hasSubText }) => ({
          title: label,
          detailRoute: route
            .replace(':regionId', regionId)
            .replace(':regionName', regionName)
            .replace(':siteId', siteId)
            .replace(':siteName', siteName),
          _id: countryId,
          icon: require(`../../assets/images/${name}.svg`).default,
          name: sessionStorageServices.getItem(APPCONSTANTS.COUNTRY_NAME),
          hasSubText
        }));
    }
  }, [
    isCommunity,
    communityReports,
    reportPrivileges,
    regionId,
    regionName,
    siteId,
    siteName,
    countryId,
    nonCommunityReports
  ]);

  return (
    <>
      <div className={`position-relative ${styles.regionContainer}`}>
        <div className='row'>
          {loading && <Loader />}
          {parsedData.map((data) => (
            <div className='col-3 p-1 width-20' key={data.title}>
              <div className={styles.cards}>
                <Link to={data.detailRoute}>
                  <div className='row p-2'>
                    <img src={data.icon} alt={`${data.title}_logo`} width={60} height={70} />
                  </div>
                  <div
                    className={`row ${styles.report_card_text} ${!isCommunity && styles.report_card_text_nc} ${
                      data.hasSubText ? styles.report_card_with_subtext : ''
                    } pb-1`}
                  >
                    <p>{data.title.toUpperCase()}</p>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        show={showAlertModal.show}
        isAlertModal={true}
        title=''
        size='modal-md'
        autoHeight={true}
        hasFooter={false}
        // tslint:disable-next-line:no-empty
        handleFormSubmit={() => {}}
        render={alertModalRender}
      />
    </>
  );
};

export default ReportList;

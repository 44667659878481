import * as SITE_ACTION_TYPES from '../site/actionTypes';

import { SiteActions, ISiteState } from './types';

const initialState: ISiteState = {
  total: 0,
  siteList: [],
  loading: false,
  error: null,
  accountList: [],
  accountDropdownLoading: true,
  countyList: [],
  countyDropdownLoading: true,
  operatingUnitList: [],
  operatingUnitDropdownLoading: false,
  detail: {
    id: 0,
    name: '',
    city: '',
    phoneNumber: '',
    tenantId: 0,
    userId: null
  }
};

const siteReducer = (state: ISiteState = initialState, action: SiteActions) => {
  switch (action.type) {
    case SITE_ACTION_TYPES.FETCH_SITE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        total: action.payload.total || 0,
        siteList: action.payload.sites || []
      };
    case SITE_ACTION_TYPES.FETCH_SITE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SITE_ACTION_TYPES.FETCH_SITE_LIST_FAILURE:
      return {
        ...state,
        loading: false
      };
    case SITE_ACTION_TYPES.SET_SITE_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.payload
        }
      };
    case SITE_ACTION_TYPES.CLEAR_SITE_DETAIL:
      return {
        ...state,
        detail: initialState.detail
      };
    default:
      return state;
  }
};

export default siteReducer;

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AppLayout } from './components/appLayout/AppLayout';
import Loader from './components/loader/Loader';
import APPCONSTANTS from './constants/appConstants';
import { PROTECTED_ROUTES } from './constants/route';
import Region from './containers/region/Region';
import AssessmentReport from './containers/report/AssessmentReport';
import DailyReport from './containers/report/DailyReport';
import EnrolledPatientsReport from './containers/report/EnrolledPatientsReport';
import HFReport from './containers/report/HFReport';
import MOHReport from './containers/report/MOHReport';
import PatientStatusReport from './containers/report/PatientStatusReport';
import PermanentReport from './containers/report/PermanentReport';
import PrescriptionReport from './containers/report/PrescriptionReport';
import ReportList from './containers/report/ReportList';
import ScreenedReport from './containers/report/ScreenedReport';
import UserPerformance from './containers/report/UserPerformance';
import SiteList from './containers/site/SiteList';
import UserRoles from './containers/userRoles/UserRoles';
import useUserAdmin from './hooks/userAdmin';
import { getIsLoggedInSelector, initializingSelector, roleSelector } from './store/user/selectors';
import { routeToParentApp } from './utils/routeUtils';

interface IRoute {
  path: string;
  exact: boolean;
  component: React.FunctionComponent<any> | React.ComponentClass<any>;
}

interface IProtectedRoute extends IRoute {
  authorisedRoles?: string[];
}

export const AppRoutes = () => {
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const role = useSelector(roleSelector);
  const initializingApp = useSelector(initializingSelector);
  const { REPORT_ADMIN, FACILITY_REPORT_ADMIN, HF4_REPORT_USER, REPORT_SUPER_ADMIN } = APPCONSTANTS.ROLES;
  const { regionDashboardRoles, HomeUrl } = useUserAdmin();

  useEffect(() => {
    if (!isLoggedIn && !initializingApp) {
      routeToParentApp();
    }
  }, [isLoggedIn, initializingApp]);

  const protectedRoutes: IProtectedRoute[] = (() => {
    return [
      {
        path: PROTECTED_ROUTES.regionDashboard,
        exact: true,
        component: Region,
        authorisedRoles: regionDashboardRoles
      },
      {
        path: PROTECTED_ROUTES.siteByRegion,
        exact: true,
        component: SiteList,
        authorisedRoles: [REPORT_SUPER_ADMIN, REPORT_ADMIN, FACILITY_REPORT_ADMIN, HF4_REPORT_USER]
      },
      {
        path: PROTECTED_ROUTES.reportList,
        exact: true,
        component: ReportList,
        authorisedRoles: [REPORT_SUPER_ADMIN, REPORT_ADMIN, FACILITY_REPORT_ADMIN, HF4_REPORT_USER]
      },
      {
        path: PROTECTED_ROUTES.dailyReport,
        exact: true,
        component: DailyReport,
        authorisedRoles: [REPORT_SUPER_ADMIN, REPORT_ADMIN, FACILITY_REPORT_ADMIN]
      },
      {
        path: PROTECTED_ROUTES.permanentReport,
        exact: true,
        component: PermanentReport,
        authorisedRoles: [REPORT_SUPER_ADMIN, REPORT_ADMIN, FACILITY_REPORT_ADMIN]
      },
      {
        path: PROTECTED_ROUTES.MOHReport,
        exact: true,
        component: MOHReport,
        authorisedRoles: [REPORT_SUPER_ADMIN, REPORT_ADMIN, FACILITY_REPORT_ADMIN]
      },
      {
        path: PROTECTED_ROUTES.prescriptionReport,
        exact: true,
        component: PrescriptionReport,
        authorisedRoles: [REPORT_SUPER_ADMIN, REPORT_ADMIN, FACILITY_REPORT_ADMIN]
      },
      {
        path: PROTECTED_ROUTES.screenedReport,
        exact: true,
        component: ScreenedReport,
        authorisedRoles: [REPORT_SUPER_ADMIN, REPORT_ADMIN, FACILITY_REPORT_ADMIN]
      },
      {
        path: PROTECTED_ROUTES.enrolledPatientReport,
        exact: true,
        component: EnrolledPatientsReport,
        authorisedRoles: [REPORT_SUPER_ADMIN, REPORT_ADMIN, FACILITY_REPORT_ADMIN]
      },
      {
        path: PROTECTED_ROUTES.assessmentReport,
        exact: true,
        component: AssessmentReport,
        authorisedRoles: [REPORT_SUPER_ADMIN, REPORT_ADMIN, FACILITY_REPORT_ADMIN]
      },
      {
        path: PROTECTED_ROUTES.userPerformance,
        exact: true,
        component: UserPerformance,
        authorisedRoles: [REPORT_SUPER_ADMIN, REPORT_ADMIN, FACILITY_REPORT_ADMIN]
      },
      {
        path: PROTECTED_ROUTES.patientStatusReport,
        exact: true,
        component: PatientStatusReport,
        authorisedRoles: [REPORT_SUPER_ADMIN, REPORT_ADMIN, FACILITY_REPORT_ADMIN]
      },
      {
        path: PROTECTED_ROUTES.HFReport,
        exact: true,
        component: HFReport,
        authorisedRoles: [REPORT_ADMIN, FACILITY_REPORT_ADMIN, HF4_REPORT_USER]
      },
      {
        path: PROTECTED_ROUTES.manageUserRoles,
        exact: true,
        component: UserRoles,
        authorisedRoles: [REPORT_SUPER_ADMIN, REPORT_ADMIN, FACILITY_REPORT_ADMIN, HF4_REPORT_USER]
      }
    ];
  })();

  return isLoggedIn ? (
    <AppLayout>
      <Switch>
        {protectedRoutes.map((route: any) =>
          route.authorisedRoles?.includes(role) ? (
            <Route
              path={route.path}
              exact={route.exact}
              key={route.path}
              render={(routeProps: any) => <route.component key={routeProps.location.key} {...routeProps} />}
            />
          ) : null
        )}
        <Redirect exact={true} to={HomeUrl} />
      </Switch>
    </AppLayout>
  ) : (
    <Loader />
  );
};

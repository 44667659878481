import * as USER_TYPES from './actionTypes';
import {
  ILogoutRequest,
  ILogoutSuccess,
  ILogoutFailure,
  IFetchTimezoneListSuccessPayload,
  IUser,
  IRole,
  IFetchCHWUsersRequest,
  IFetchCHWUsersSuccess,
  IFetchCHWUsersFailure,
  ISetHF4UserStatus,
  ISetAppType,
  ISetReportPrivileges
} from './types';

export const logoutRequest = (): ILogoutRequest => ({
  type: USER_TYPES.LOGOUT_REQUEST
});

export const logoutSuccess = (): ILogoutSuccess => ({
  type: USER_TYPES.LOGOUT_SUCCESS
});

export const logoutFailure = (): ILogoutFailure => ({
  type: USER_TYPES.LOGOUT_FAILURE
});

export const fetchTimezoneListRequest = () => ({
  type: USER_TYPES.FETCH_TIMEZONE_LIST_REQUEST
});

export const fetchTimezoneListSuccess = (payload: IFetchTimezoneListSuccessPayload) => ({
  type: USER_TYPES.FETCH_TIMEZONE_LIST_SUCCESS,
  payload
});

export const fetchTimezoneListFailure = () => ({
  type: USER_TYPES.FETCH_TIMEZONE_LIST_FAILURE
});

export const sessionTimedout = (message: string) => ({
  type: USER_TYPES.SESSION_TIMEDOUT,
  message
});

export const resetStore = () => ({
  type: USER_TYPES.RESET_STORE
});

export const fetchLoggedInUser = () => ({
  type: USER_TYPES.FETCH_LOGGED_IN_USER_REQUEST
});

export const fetchLoggedInUserSuccess = (payload: IUser) => ({
  type: USER_TYPES.FETCH_LOGGED_IN_USER_SUCCESS,
  payload
});

export const fetchLoggedInUserFail = () => ({
  type: USER_TYPES.FETCH_LOGGED_IN_USER_FAILURE
});

export const fetchDefaultRole = () => ({
  type: USER_TYPES.FETCH_DEFAULT_ROLE
});

export const fetchDefaultRoleSuccess = (data: IRole) => ({
  type: USER_TYPES.FETCH_DEFAULT_ROLE_SUCCESS,
  data
});

export const fetchDefaultRoleFailure = () => ({
  type: USER_TYPES.FETCH_DEFAULT_ROLE_FAILURE
});

export const fetchCountryName = () => ({
  type: USER_TYPES.FETCH_COUNTRY_NAME
});

export const clearCountryName = () => ({
  type: USER_TYPES.CLEAR_COUNTRY_NAME
});

export const clearCountryId = () => ({
  type: USER_TYPES.CLEAR_COUNTRY_ID
});

export const fetchCHWUserList = ({
  skip,
  limit,
  tenantId,
  successCb,
  failureCb
}: Omit<IFetchCHWUsersRequest, 'type'>): IFetchCHWUsersRequest => ({
  type: USER_TYPES.FETCH_CHW_USER_LIST_REQUEST,
  skip,
  limit,
  tenantId,
  successCb,
  failureCb
});

export const fetchCHWUsersSuccess = (payload: IUser[]): IFetchCHWUsersSuccess => ({
  type: USER_TYPES.FETCH_CHW_USER_LIST_SUCCESS,
  payload
});

export const fetchCHWUsersFailure = (): IFetchCHWUsersFailure => ({
  type: USER_TYPES.FETCH_CHW_USER_LIST_FAILURE
});

export const setHF4UserStatus = (payload: boolean): ISetHF4UserStatus => ({
  type: USER_TYPES.SET_HF4_USER_STATUS,
  payload
});

export const setAppType = (appTypes: string[]): ISetAppType => ({
  type: USER_TYPES.SET_APP_TYPE,
  payload: appTypes
});

export const setIsKenya = (value: boolean) => ({
  type: USER_TYPES.SET_IS_KENYA,
  value
});

export const setReportPrivileges = (payload: string[]): ISetReportPrivileges => ({
  type: USER_TYPES.SET_REPORT_PRIVILEGES,
  payload
});

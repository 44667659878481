export const FETCH_SCREENED_PATIENT_LIST_REQUEST = 'FETCH_SCREENED_PATIENT_LIST_REQUEST';
export const FETCH_SCREENED_PATIENT_SUCCESS = 'FETCH_SCREENED_PATIENT_SUCCESS';
export const FETCH_SCREENED_PATIENT_FAILURE = 'FETCH_SCREENED_PATIENT_FAILURE';
export const FETCH_ENROLLED_PATIENT_REQUEST = 'FETCH_ENROLLED_PATIENT_REQUEST';
export const FETCH_ENROLLED_PATIENT_SUCCESS = 'FETCH_ENROLLED_PATIENT_SUCCESS';
export const FETCH_ENROLLED_PATIENT_FAILURE = 'FETCH_ENROLLED_PATIENT_FAILURE';
export const FETCH_ASSESSMENT_LIST_REQUEST = 'FETCH_ASSESSMENT_LIST_REQUEST';
export const FETCH_ASSESSMENT_LIST_SUCCESS = 'FETCH_ASSESSMENT_LIST_SUCCESS';
export const FETCH_ASSESSMENT_LIST_FAILURE = 'FETCH_ASSESSMENT_LIST_FAILURE';
export const FETCH_USER_PERFORMANCE_LIST_REQUEST = 'FETCH_USER_PERFORMANCE_LIST_REQUEST';
export const FETCH_USER_PERFORMANCE_LIST_SUCCESS = 'FETCH_USER_PERFORMANCE_LIST_SUCCESS';
export const FETCH_USER_PERFORMANCE_LIST_FAILURE = 'FETCH_USER_PERFORMANCE_LIST_FAILURE';

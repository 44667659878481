import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { ReactComponent as HomeIcon } from '../../assets/images/home.svg';
import { PROTECTED_ROUTES } from '../../constants/route';
import { getRegionDetailSelector } from '../../store/region/selectors';
import { getSiteDetailSelector } from '../../store/site/selectors';

import APPCONSTANTS from '../../constants/appConstants';
import sessionStorageService from '../../global/sessionStorageServices';
import useUserAdmin from '../../hooks/userAdmin';
import { clearRegionDetail } from '../../store/region/actions';
import { fetchCountryName, setAppType, setIsKenya } from '../../store/user/actions';
import { userSuiteAccessSelector } from '../../store/user/selectors';
import { parseURLData, reportNameFormat } from '../../utils/commonUtils';
import styles from './Breadcrumb.module.scss';

export interface ICustomBreadcrumb {
  label: string;
  route: string;
  active: boolean;
}

const defaultBreadcrumbs: ICustomBreadcrumb[] = [
  { route: PROTECTED_ROUTES.manageUserRoles, label: 'Manage User Roles', active: true }
];

const classNameStyle = `fs-1dot5 fw-bold no-pointer-events ${styles.active}`;
/**
 * Dynamic breadcrumb for application
 * @returns {React.ReactElement}
 */
const Breadcrumb = ({ custom }: { custom?: ICustomBreadcrumb[] }): React.ReactElement => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const region = useSelector(getRegionDetailSelector);
  const site = useSelector(getSiteDetailSelector);
  const userSuiteAccess = useSelector(userSuiteAccessSelector);
  const { countryId, regionName, siteId, siteName, reportType } = parseURLData(pathname);
  const { isUserAdmin, HomeUrl } = useUserAdmin();

  const regionData = useMemo(() => {
    const sessionRegionId = sessionStorageService.getItem(APPCONSTANTS.REGION_ID);
    const sessionRegionName = sessionStorageService.getItem(APPCONSTANTS.COUNTRY_NAME);
    return { regionId: region.id || sessionRegionId, regionName: region.name || sessionRegionName };
  }, [region]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const customBreadcrumb = custom?.length ? custom : [];

  const activeRoute = useMemo(
    () =>
      Object.values(PROTECTED_ROUTES).find((route) => Boolean(matchPath(pathname, { path: route, exact: true }))) ?? '',
    [pathname]
  );

  const handleHome = useCallback(() => {
    // clear data for is user admin
    if (isUserAdmin) {
      dispatch(clearRegionDetail());
      dispatch(fetchCountryName());
      dispatch(setIsKenya(false));
      dispatch(setAppType([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAdmin]);

  const defaultBreadcrumb = defaultBreadcrumbs.find(({ route }) =>
    Boolean(matchPath(pathname, { path: route, exact: true }))
  );

  const activeRouteConditions = (
    result: any,
    pageType: {
      isMOHReportPage: boolean;
      isPermanentReportPage: boolean;
      isPatientStatusReport: boolean;
      isDailyReportPage: boolean;
      isScreenedReportPage: boolean;
      isEnrolledPatientReport: boolean;
      isAssessmentReport: boolean;
      isPrescriptionReport: boolean;
      isUserPerformance: boolean;
      isHFReport: boolean;
    },
    reportName: string
  ) => {
    if (pageType.isMOHReportPage) {
      result.push({
        label: APPCONSTANTS.REPORT_LIST_LABEL.MONTHLY_SUMMARY,
        route: PROTECTED_ROUTES.reportList
          .replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    } else if (pageType.isPermanentReportPage) {
      result.push({
        label: APPCONSTANTS.REPORT_LIST_LABEL.PERMANANT_REGISTER,
        route: PROTECTED_ROUTES.reportList
          .replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    } else if (pageType.isPatientStatusReport) {
      result.push({
        label: APPCONSTANTS.REPORT_LIST_LABEL.PATIENT_STATUS_REPORT,
        route: PROTECTED_ROUTES.reportList
          .replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    } else if (pageType.isDailyReportPage) {
      result.push({
        label: APPCONSTANTS.REPORT_LIST_LABEL.DAILY_ACTIVITY_REGISTER,
        route: PROTECTED_ROUTES.reportList
          .replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    } else if (pageType.isScreenedReportPage) {
      result.push({
        label: APPCONSTANTS.REPORT_LIST_LABEL.SCREENED_PATIENTS,
        route: PROTECTED_ROUTES.reportList
          .replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    } else if (pageType.isEnrolledPatientReport) {
      result.push({
        label: APPCONSTANTS.REPORT_LIST_LABEL.ENROLLED_PATIENTS,
        route: PROTECTED_ROUTES.reportList
          .replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    } else if (pageType.isAssessmentReport) {
      result.push({
        label: APPCONSTANTS.REPORT_LIST_LABEL.ASSESSMENT_REPORT,
        route: PROTECTED_ROUTES.reportList
          .replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    } else if (pageType.isPrescriptionReport) {
      result.push({
        label: APPCONSTANTS.REPORT_LIST_LABEL.PRESCRIPTION_MEDICATION,
        route: PROTECTED_ROUTES.reportList
          .replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    } else if (pageType.isUserPerformance) {
      result.push({
        label: APPCONSTANTS.REPORT_LIST_LABEL.USER_PERFORMANCE,
        route: PROTECTED_ROUTES.reportList
          .replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    } else if (pageType.isHFReport) {
      result.push({
        label: reportNameFormat(reportName),
        route: PROTECTED_ROUTES.HFReport.replace(':regionId', regionData.regionName ?? ' ')
          .replace(':regionName', regionData.regionName ?? ' ')
          .replace(':siteId', siteId ?? ' ')
          .replace(':siteName', siteName ?? ' '),
        active: activeRoute.includes(':siteId')
      });
    }
  };

  const regionDashboardFunction = (result: any, customSections: ICustomBreadcrumb[], showRegion: boolean) => {
    if (
      Boolean(matchPath(pathname, { path: PROTECTED_ROUTES.regionDashboard, exact: true })) ||
      (isUserAdmin && activeRoute.includes('region'))
    ) {
      result.push({
        label: 'Regions',
        route: PROTECTED_ROUTES.regionDashboard,
        active: activeRoute.includes('region') && !activeRoute.includes(':regionId')
      });
    } else if (countryId !== 'undefined' && countryId) {
      result.push({
        label: 'Sites',
        route: PROTECTED_ROUTES.siteByRegion.replace(':regionId', countryId).replace(':regionName', regionName || ' '),
        active: activeRoute.includes(':regionId') && !customSections.length && !activeRoute.includes(':siteId')
      });
    }
    return result;
  };

  const sections = useMemo(
    (customSections: ICustomBreadcrumb[] = customBreadcrumb) => {
      const result: ICustomBreadcrumb[] = [];
      const showSite = activeRoute.includes(':siteId');
      const showRegion = showSite || (activeRoute.includes(':regionId') && regionName !== 'undefined');
      // we have a default breadcrumb for certain routes
      if (defaultBreadcrumb) {
        // this if block executes when current route is one of defaultBreadcrumbs routes
        result.push(defaultBreadcrumb);
        return result;
      }
      regionDashboardFunction(result, customSections, showRegion);
      if (isUserAdmin && activeRoute.includes(':regionId')) {
        result.push({
          label: regionData.regionName ?? ' ',
          route: PROTECTED_ROUTES.siteByRegion
            .replace(':regionId', regionData.regionId ?? ' ')
            .replace(':regionName', regionData.regionName ?? ' '),
          active: activeRoute.includes(':regionId') && !activeRoute.includes(':siteId') && !customSections.length
        });
      }
      if ((site?.name.replace(/[/\s]/g, '') && showSite) || (siteName && showSite)) {
        const isMOHReportPage = Boolean(matchPath(pathname, { path: PROTECTED_ROUTES.MOHReport, exact: true }));
        const isPermanentReportPage = Boolean(
          matchPath(pathname, {
            path: PROTECTED_ROUTES.permanentReport,
            exact: true
          })
        );
        const isPatientStatusReport = Boolean(
          matchPath(pathname, {
            path: PROTECTED_ROUTES.patientStatusReport,
            exact: true
          })
        );
        const isDailyReportPage = Boolean(matchPath(pathname, { path: PROTECTED_ROUTES.dailyReport, exact: true }));
        const isScreenedReportPage = Boolean(
          matchPath(pathname, { path: PROTECTED_ROUTES.screenedReport, exact: true })
        );
        const isEnrolledPatientReport = Boolean(
          matchPath(pathname, { path: PROTECTED_ROUTES.enrolledPatientReport, exact: true })
        );
        const isAssessmentReport = Boolean(
          matchPath(pathname, { path: PROTECTED_ROUTES.assessmentReport, exact: true })
        );
        const isPrescriptionReport = Boolean(
          matchPath(pathname, { path: PROTECTED_ROUTES.prescriptionReport, exact: true })
        );
        const isUserPerformance = Boolean(matchPath(pathname, { path: PROTECTED_ROUTES.userPerformance, exact: true }));
        const isHFReport = Boolean(matchPath(pathname, { path: PROTECTED_ROUTES.HFReport, exact: true }));
        result.push({
          label: siteName ?? ' ',
          route: PROTECTED_ROUTES.reportList
            .replace(':regionId', regionData.regionId ?? ' ')
            .replace(':regionName', regionData.regionName ?? ' ')
            .replace(':siteId', siteId ?? ' ')
            .replace(':siteName', site.name.replace(/\//g, '') || siteName || ' '),
          active:
            !(
              isHFReport ||
              isMOHReportPage ||
              isPermanentReportPage ||
              isPatientStatusReport ||
              isDailyReportPage ||
              isScreenedReportPage ||
              isEnrolledPatientReport ||
              isAssessmentReport ||
              isPrescriptionReport ||
              isUserPerformance
            ) && activeRoute.includes(':siteId')
        });
        activeRouteConditions(
          result,
          {
            isMOHReportPage,
            isPermanentReportPage,
            isPatientStatusReport,
            isDailyReportPage,
            isScreenedReportPage,
            isEnrolledPatientReport,
            isAssessmentReport,
            isPrescriptionReport,
            isUserPerformance,
            isHFReport
          },
          reportType
        );
      }
      if (customSections?.length) {
        customSections.forEach((section) => {
          result.push({
            label: section.label,
            route: section.route,
            active: section.active
          });
        });
      }
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [region, site, pathname, activeRoute, customBreadcrumb, defaultBreadcrumb, countryId]
  );

  const authorisedWebSuiteAccess = ['admin', 'cfr', 'insights'];
  const authorisedUserSuiteAccess = userSuiteAccess.filter((suiteAccess) =>
    authorisedWebSuiteAccess.includes(suiteAccess)
  );

  return (
    <div className={`${styles.breadcrumb} d-flex align-items-center`}>
      {authorisedUserSuiteAccess?.length > 1 ? (
        <a
          href={process.env.REACT_APP_SPICE_WEB_URL}
          className={`${styles.homeIcon} d-inline-flex align-items-center justify-content-center me-dot75 lh-0`}
        >
          <HomeIcon className='d-inline-block' aria-labelledby='Home' aria-label='Home' />
        </a>
      ) : (
        <Link
          className={`${styles.homeIcon} d-inline-flex align-items-center justify-content-center me-dot75 lh-0`}
          to={HomeUrl}
          onClick={() => {
            handleHome();
          }}
        >
          <HomeIcon className='d-inline-block' aria-labelledby='Home' aria-label='Home' />
        </Link>
      )}
      <div>
        {sections.length ? (
          sections.map(({ label, route, active }, i) => (
            <React.Fragment key={label}>
              {!!i && <span className='subtle-color mx-dot25 align-baseline'>/</span>}
              <Link to={route} className={`align-baseline ${styles.breadcrumbLink} ${active ? classNameStyle : ''}`}>
                {label}
              </Link>
            </React.Fragment>
          ))
        ) : (
          <span className='fs-1dot5 primary-title fw-bold'>Home</span>
        )}
      </div>
    </div>
  );
};

export default Breadcrumb;

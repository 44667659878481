import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as reportService from '../../services/reportAPI';
import {
  IFetchAssessmentListRequest,
  IFetchIEnrolledPatientStateRequest,
  IFetchIScreenedListStateRequest,
  IFetchIUserPerformanceListStateRequest
} from '../report/types';
import {
  fetchAssessmentListFailure,
  fetchAssessmentListSuccess,
  fetchEnrolledPatientFailure,
  fetchEnrolledPatientSuccess,
  fetchScreenedPatientListFailure,
  fetchScreenedPatientListSuccess,
  fetchUserPerformanceListFailure,
  fetchUserPerformanceListSuccess
} from './actions';
import {
  FETCH_ASSESSMENT_LIST_REQUEST,
  FETCH_ENROLLED_PATIENT_REQUEST,
  FETCH_SCREENED_PATIENT_LIST_REQUEST,
  FETCH_USER_PERFORMANCE_LIST_REQUEST
} from './actionTypes';

/*
  Worker Saga: Fired on FETCH_SCREENED_PATIENT_LIST_REQUEST action
*/
export function* fetchScreenedList(action: IFetchIScreenedListStateRequest): SagaIterator {
  try {
    const {
      data: { entityList: screenedList, totalCount, limit }
    } = yield call(reportService.fetchScreenedReport, action.payload);
    const payload = { screenedList, totalCount, limit };
    yield put(fetchScreenedPatientListSuccess(payload));
  } catch (e) {
    if (e instanceof Error) {
      action.payload.failureCb?.(e);
      yield put(fetchScreenedPatientListFailure(e));
    }
  }
}

/*
  Worker Saga: Fired on FETCH_ENROLLED_PATIENT_REQUEST action
*/
export function* fetchEnrolledPatientList(action: IFetchIEnrolledPatientStateRequest): SagaIterator {
  try {
    const {
      data: { entityList: enrolledPatientList, totalCount, limit }
    } = yield call(reportService.fetchEnrolledPatientReport, action.payload);
    const payload = { enrolledPatientList, totalCount, limit };
    yield put(fetchEnrolledPatientSuccess(payload));
  } catch (e) {
    if (e instanceof Error) {
      action.payload.failureCb?.(e);
      yield put(fetchEnrolledPatientFailure(e));
    }
  }
}
/*
  Worker Saga: Fired on FETCH_ASSESSMENT_LIST_REQUEST action
*/
export function* fetchAssessmentList(action: IFetchAssessmentListRequest): SagaIterator {
  try {
    const {
      data: { entityList: assessmentList, totalCount, limit }
    } = yield call(reportService.fetchAssessmentListReport, action.payload);
    const payload = { assessmentList, totalCount, limit };
    yield put(fetchAssessmentListSuccess(payload));
  } catch (e) {
    if (e instanceof Error) {
      action.payload.failureCb?.(e);
      yield put(fetchAssessmentListFailure(e));
    }
  }
}

/*
  Worker Saga: Fired on FETCH_USER_PERFORMANCE_LIST_REQUEST action
*/
function* fetchUserPerformanceList(action: IFetchIUserPerformanceListStateRequest): SagaIterator {
  try {
    const {
      data: { entityList: userPerformanceList, totalCount, limit }
    } = yield call(reportService.fetchUserPerformanceReport, action.payload);
    const payload = { userPerformanceList, totalCount, limit };
    yield put(fetchUserPerformanceListSuccess(payload));
  } catch (e) {
    if (e instanceof Error) {
      action.payload.failureCb?.(e);
      yield put(fetchUserPerformanceListFailure(e));
    }
  }
}

/*
  Starts worker saga on latest dispatched specific action.
  Allows concurrent increments.
*/
function* reportSaga() {
  yield all([takeLatest(FETCH_SCREENED_PATIENT_LIST_REQUEST, fetchScreenedList)]);
  yield all([takeLatest(FETCH_ENROLLED_PATIENT_REQUEST, fetchEnrolledPatientList)]);
  yield all([takeLatest(FETCH_ASSESSMENT_LIST_REQUEST, fetchAssessmentList)]);
  yield all([takeLatest(FETCH_USER_PERFORMANCE_LIST_REQUEST, fetchUserPerformanceList)]);
}

export default reportSaga;

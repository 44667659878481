import { createSelector } from 'reselect';

import { AppState } from '../rootReducer';

const getIsLoggedIn = (state: AppState) => state.user.isLoggedIn;
const getIsLoggingIn = (state: AppState) => state.user.loggingIn;
const getIsLoggingOut = (state: AppState) => state.user.loggingOut;
const getFirstName = (state: AppState) => state.user.user.firstName;
const getLastName = (state: AppState) => state.user.user.lastName;
const getRole = (state: AppState) => state.user.user.role;
const getRoles = (state: AppState) => state.user.user.roleDetail;
const getTimezoneList = (state: AppState) => state.user.timezoneList;
const getLoading = (state: AppState) => state.user.loading;
const getInitializing = (state: AppState) => state.user.initializing;
const getCountryName = (state: AppState) => state.user.user.country;
const getCountry = (state: AppState) => state.user.countryName;
const getCountryId = (state: AppState) => state.user.user.country;
const getUsername = (state: AppState) => state.user.user.email;
const getUserData = (state: AppState) => state.user.user;
const getUserSuiteAccess = (state: AppState) => state.user.user.suiteAccess;
const getIsHF4User = (state: AppState) => state.user.user.isHF4User;
const getReportPrivileges = (state: AppState) => state.user.user.reportPrivileges;
const getCHWUserList = (state: AppState) => state.user.chwUserList;
const getCHWUserListLoading = (state: AppState) => state.user.chwUserListLoading;
const getAppType = (state: AppState) => state.user.user?.appTypes;
const getIsKenya = (state: AppState) => state.user.isKenya;

export const getIsLoggedInSelector = createSelector(getIsLoggedIn, (isLoggedIn) => isLoggedIn);

export const getIsLoggingInSelector = createSelector(getIsLoggingIn, (loggingIn) => loggingIn);

export const getIsLoggingOutSelector = createSelector(getIsLoggingOut, (loggingOut) => loggingOut);

export const firstNameSelector = createSelector(getFirstName, (firstName) => firstName);

export const lastNameSelector = createSelector(getLastName, (lastName) => lastName);

export const timezoneListSelector = createSelector(getTimezoneList, (timezoneList) => timezoneList);

export const loadingSelector = createSelector(getLoading, (loading) => loading);

export const initializingSelector = createSelector(getInitializing, (initializing) => initializing);

export const roleSelector = createSelector(getRole, (role) => role);

export const rolesSelector = createSelector(getRoles, (roles) => roles);

export const countrySelector = createSelector(getCountryName, (country) => country);

export const countryNameSelector = createSelector(getCountry, (country) => country);

export const countryIdSelector = createSelector(getCountryId, (countryId) => countryId);

export const usernameSelector = createSelector(getUsername, (username) => username);

export const userDataSelector = createSelector(getUserData, (user) => user);
export const isHF4UserSelector = createSelector(getIsHF4User, (status) => status);
export const userSuiteAccessSelector = createSelector(getUserSuiteAccess, (user) => user);
export const chwUserListSelector = createSelector(getCHWUserList, (user) => user);
export const chwUserListLoadingSelector = createSelector(getCHWUserListLoading, (loading) => loading);
export const getAppTypeSelector = createSelector(getAppType, (appTypes) => appTypes);
export const isKenyaSelector = createSelector(getIsKenya, (isKenya) => isKenya);
export const reportPrivilegesSelector = createSelector(getReportPrivileges, (privileges) => privileges);

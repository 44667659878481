import {
  IFetchAssessmentListFailure,
  IFetchAssessmentListRequest,
  IFetchAssessmentListSuccess,
  IFetchAssessmentListSuccessPayload,
  IFetchEnrolledPatientSuccessPayload,
  IFetchIEnrolledPatientStateFailure,
  IFetchIEnrolledPatientStateRequest,
  IFetchIEnrolledPatientStateSuccess,
  IFetchIScreenedListStateFailure,
  IFetchIScreenedListStateRequest,
  IFetchIScreenedListStateSuccess,
  IFetchIUserPerformanceListStateFailure,
  IFetchIUserPerformanceListStateRequest,
  IFetchIUserPerformanceListStateSuccess,
  IFetchScreenedListSuccessPayload,
  IFetchUserPerformanceListSuccessPayload
} from '../report/types';
import * as REPORT_TYPES from './actionTypes';

export const fetchScreenedPatientListRequest = ({
  payload
}: Omit<IFetchIScreenedListStateRequest, 'type'>): IFetchIScreenedListStateRequest => ({
  type: REPORT_TYPES.FETCH_SCREENED_PATIENT_LIST_REQUEST,
  payload
});

export const fetchScreenedPatientListSuccess = (
  payload: IFetchScreenedListSuccessPayload
): IFetchIScreenedListStateSuccess => ({
  type: REPORT_TYPES.FETCH_SCREENED_PATIENT_SUCCESS,
  payload
});

export const fetchScreenedPatientListFailure = (error: Error): IFetchIScreenedListStateFailure => ({
  type: REPORT_TYPES.FETCH_SCREENED_PATIENT_FAILURE,
  error
});

export const fetchEnrolledPatientRequest = ({
  payload
}: Omit<IFetchIEnrolledPatientStateRequest, 'type'>): IFetchIEnrolledPatientStateRequest => ({
  type: REPORT_TYPES.FETCH_ENROLLED_PATIENT_REQUEST,
  payload
});

export const fetchEnrolledPatientSuccess = (
  payload: IFetchEnrolledPatientSuccessPayload
): IFetchIEnrolledPatientStateSuccess => ({
  type: REPORT_TYPES.FETCH_ENROLLED_PATIENT_SUCCESS,
  payload
});

export const fetchEnrolledPatientFailure = (error: Error): IFetchIEnrolledPatientStateFailure => ({
  type: REPORT_TYPES.FETCH_ENROLLED_PATIENT_FAILURE,
  error
});

export const fetchUserPerformanceListRequest = ({
  payload
}: Omit<IFetchIUserPerformanceListStateRequest, 'type'>): IFetchIUserPerformanceListStateRequest => ({
  type: REPORT_TYPES.FETCH_USER_PERFORMANCE_LIST_REQUEST,
  payload
});

export const fetchUserPerformanceListSuccess = (
  payload: IFetchUserPerformanceListSuccessPayload
): IFetchIUserPerformanceListStateSuccess => ({
  type: REPORT_TYPES.FETCH_USER_PERFORMANCE_LIST_SUCCESS,
  payload
});

export const fetchUserPerformanceListFailure = (error: Error): IFetchIUserPerformanceListStateFailure => ({
  type: REPORT_TYPES.FETCH_USER_PERFORMANCE_LIST_FAILURE,
  error
});

export const fetchAssessmentListRequest = ({
  payload
}: Omit<IFetchAssessmentListRequest, 'type'>): IFetchAssessmentListRequest => ({
  type: REPORT_TYPES.FETCH_ASSESSMENT_LIST_REQUEST,
  payload
});

export const fetchAssessmentListSuccess = (
  payload: IFetchAssessmentListSuccessPayload
): IFetchAssessmentListSuccess => ({
  type: REPORT_TYPES.FETCH_ASSESSMENT_LIST_SUCCESS,
  payload
});

export const fetchAssessmentListFailure = (error: Error): IFetchAssessmentListFailure => ({
  type: REPORT_TYPES.FETCH_ASSESSMENT_LIST_FAILURE,
  error
});

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import UserIcon from '../../assets/images/user-role-assigned.svg';
import CustomTable from '../../components/customtable/CustomTable';
import DetailCard from '../../components/detailCard/DetailCard';
import SelectInput from '../../components/formFields/SelectInput';
import Loader from '../../components/loader/Loader';
import ModalForm from '../../components/modal/ModalForm';
import APPCONSTANTS from '../../constants/appConstants';
import ERRORS from '../../constants/error';
import { PROTECTED_ROUTES } from '../../constants/route';
import sessionStorageServices from '../../global/sessionStorageServices';
import { clearSiteDetail, fetchSiteListRequest, setSiteDetail } from '../../store/site/actions';
import { getSiteListSelector, getSiteListTotalSelector, getSiteLoadingSelector } from '../../store/site/selectors';
import { fetchCHWUserList } from '../../store/user/actions';
import { chwUserListLoadingSelector, roleSelector } from '../../store/user/selectors';
import { IUser } from '../../store/user/types';
import toastCenter from '../../utils/toastCenter';
import sessionStorageService from '../../global/sessionStorageServices';

interface IMatchParams {
  regionId: string;
  regionName: string;
}

const SiteList = (props: IMatchParams): React.ReactElement => {
  const [listParams, setListReqParams] = useState({
    page: 1,
    rowsPerPage: APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE,
    searchTerm: ''
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const sites = useSelector(getSiteListSelector);
  const loading = useSelector(getSiteLoadingSelector);
  const siteCount = useSelector(getSiteListTotalSelector);
  const chwUserListloading = useSelector(chwUserListLoadingSelector);
  const role = useSelector(roleSelector);
  const [openModal, setOpenModal] = useState({ isOpen: false, data: {} as any });
  const [userList, setUserList] = useState([] as IUser[]);

  const { regionId, regionName } = useParams<IMatchParams>();

  useEffect(() => {
    dispatch(
      fetchSiteListRequest({
        regionId: Number(regionId),
        skip: (listParams.page - 1) * listParams.rowsPerPage,
        limit: listParams.rowsPerPage,
        search: listParams.searchTerm,
        userBased: role === 'REPORT_ADMIN' ? false : true,
        failureCb: (e) => {
          if (e.message === ERRORS.NETWORK_ERROR.message) {
            toastCenter.error(APPCONSTANTS.NETWORK_ERROR, APPCONSTANTS.CONNECTION_LOST);
          } else {
            toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.SITE_FETCH_ERROR);
          }
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParams, regionId, dispatch]);

  useEffect(() => {
    dispatch(clearSiteDetail());
    sessionStorageService.deleteItems([APPCONSTANTS.CHW_USER_ID, APPCONSTANTS.SITE_TENANT_ID]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchString: string) => {
    setListReqParams((prevstate) => {
      return {
        ...prevstate,
        page: 1,
        searchTerm: searchString
      };
    });
  };

  const handlePage = (pageNo: number, rowsPerPage: number | undefined = APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE) => {
    setListReqParams((prevstate) => {
      return {
        ...prevstate,
        page: pageNo,
        rowsPerPage
      };
    });
  };

  const handleUserViewClick = (data: any, idx: number) => {
    dispatch(
      fetchCHWUserList({
        skip: null,
        limit: null,
        tenantId: data.tenantId,
        successCb: (users: IUser[]) => {
          if (!users?.length) {
            return toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.NO_CHW_AVAILABLE);
          }
          const userLists = users.map((user) => ({
            name: `${user.firstName} ${user.lastName}`,
            id: user.userId,
            ...user
          }));
          setUserList(userLists);
          setOpenModal({ isOpen: true, data });
        },
        failureCb: () => {
          toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.SITE_USERS_FETCH_ERROR);
        }
      })
    );
  };

  const onModalCancel = () => {
    setOpenModal({ ...openModal, isOpen: false });
  };

  const handleUserSelection = (data: any, isFromUserView: boolean = false) => {
    const { id: userId } = data;
    const { id: siteId, tenantId: siteTenantId, name: siteName } = openModal.data.id ? openModal.data : data;

    sessionStorageServices.setItem(APPCONSTANTS.SITE_TENANT_ID, siteTenantId);
    sessionStorageServices.setItem(APPCONSTANTS.SITE_ID, siteId);
    const siteDetails = {
      name: siteName,
      id: siteId,
      tenantId: siteTenantId,
      ...(isFromUserView && { userId })
    };

    if (isFromUserView) {
      sessionStorageServices.setItem(APPCONSTANTS.CHW_USER_ID, userId);
    }

    dispatch(setSiteDetail(siteDetails));
    history.push(
      PROTECTED_ROUTES.reportList
        .replace(':regionId', regionId)
        .replace(':regionName', regionName)
        .replace(':siteId', siteId)
        .replace(':siteName', siteName.replace(/\//g, ''))
        .replace(':tenantId', siteTenantId)
    );
    setOpenModal({ isOpen: false, data: {} });
  };

  const userSelectRender = () => {
    return (
      <SelectInput
        label='Select CHW'
        errorLabel='user'
        labelKey='name'
        valueKey='id'
        isFullWidth={true}
        options={userList}
        loadingOptions={false}
        isModel={true}
        onChange={(data) => handleUserSelection(data, true)}
      />
    );
  };

  return (
    <>
      {(loading || chwUserListloading) && <Loader />}
      <div className='col-12'>
        <DetailCard header='Sites' isSearch={true} onSearch={handleSearch}>
          <CustomTable
            rowData={sites}
            columnsDef={[
              {
                id: 1,
                name: 'name',
                label: 'Name'
              },
              {
                id: 2,
                name: 'cityName',
                label: 'City/Town'
              },
              {
                id: 3,
                name: 'phuFocalPersonNumber',
                label: 'Phone Number',
                class: 'numeric'
              }
            ]}
            isDelete={false}
            isEdit={false}
            isView={true}
            viewToolTip='Site View'
            isDownload={false}
            page={listParams.page}
            rowsPerPage={listParams.rowsPerPage}
            count={siteCount}
            onRowView={handleUserSelection}
            handlePageChange={handlePage}
            isCustom={true}
            customProps={
              role === APPCONSTANTS.ROLES.HF4_REPORT_USER
                ? [
                    {
                      title: '',
                      icon: UserIcon,
                      style: { width: 18 },
                      onClick: (_, data, idx) => handleUserViewClick(data, idx),
                      tooltipFormatter: (data: any) => 'User View'
                    }
                  ]
                : []
            }
          />
        </DetailCard>
      </div>
      <ModalForm
        show={openModal.isOpen}
        title={'User View'}
        isCancelButtonNeeded={false}
        handleCancel={onModalCancel}
        size={'modal-md'}
        autoHeight={true}
      >
        {userSelectRender()}
      </ModalForm>
    </>
  );
};

export default SiteList;

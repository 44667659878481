import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as userActions from './actions';
import * as regionActions from '../region/actions';
import * as USERTYPES from './actionTypes';
import { IFetchCHWUsersRequest, IFetchLoggedInUserRequest, IRoles, IUser } from './types';
import * as userService from '../../services/userAPI';
import APPCONSTANTS from '../../constants/appConstants';
import sessionStorageServices from '../../global/sessionStorageServices';
import { info } from '../../utils/toastCenter';
import { routeToParentApp } from '../../utils/routeUtils';
import { AppState } from '../rootReducer';
import localStorageService from '../../global/localStorageServices';
import { APP_TYPE_NAME } from '../../constants/appConstants';

export function* logout(): SagaIterator {
  try {
    yield call(userService.logout);
    sessionStorageServices.clearAllItem();
    yield put(userActions.logoutSuccess());
    yield put(userActions.resetStore());
    routeToParentApp(true);
  } catch (e) {
    sessionStorageServices.deleteItem(APPCONSTANTS.AUTHTOKEN);
    yield put(userActions.logoutFailure());
  }
}

export function* fetchTimezoneList(): SagaIterator {
  try {
    const {
      data: { data: timezoneList }
    } = yield call(userService.fetchTimezoneList);
    yield put(userActions.fetchTimezoneListSuccess(timezoneList));
  } catch (e) {
    yield put(userActions.fetchTimezoneListFailure());
  }
}

export function* fetchLoggedInUser(action: IFetchLoggedInUserRequest): SagaIterator {
  try {
    const {
      data: {
        entity: {
          username: email,
          firstName,
          lastName,
          id: userId,
          reportPrivileges,
          roles,
          appTypes,
          tenantId,
          country,
          organizations,
          suiteAccess
        }
      }
    } = yield call(userService.fetchLoggedInUser);
    const isReportSuperAdmin = roles.some(
      (role: { name: string; suiteAccessName: string }) =>
        role.name === APPCONSTANTS.ROLES.REPORT_SUPER_ADMIN && role.suiteAccessName === APPCONSTANTS.SUITE_ACCESS.CFR
    );
    // don't put country if logged-in user is isReportSuperAdmin
    if (country && !isReportSuperAdmin) {
      yield put(regionActions.setRegionDetail(country));
      if (country.name.toLowerCase() === APPCONSTANTS.KENYA) {
        yield put(userActions.setIsKenya(true));
        sessionStorageServices.setItem(APPCONSTANTS.IS_KENYA, true);
      }
    }
    sessionStorageServices.setItem(APPCONSTANTS.USER_TENANTID, tenantId);
    sessionStorageServices.setItem(APPCONSTANTS.COUNTRY_TENANT_ID, country?.tenantId);
    const isHF4User = (roles || []).some((role: IRoles) => role.name === APPCONSTANTS.ROLES.HF4_REPORT_USER);
    yield put(userActions.setHF4UserStatus(isHF4User));
    if (reportPrivileges?.length) {
      yield put(userActions.setReportPrivileges(reportPrivileges));
    }

    const filteredRoles = roles.filter((role: any) => Object.values(APPCONSTANTS.ROLES).includes(role.name));
    if (!filteredRoles.length) {
      info(APPCONSTANTS.ALERT, APPCONSTANTS.UNAUTHORIZED_ACCESS_MESSAGE);
      yield put(userActions.logoutRequest());
    }
    const oldAppTypes = yield select((state: AppState) => state.user?.user?.appTypes);
    const payload: IUser = {
      email,
      firstName,
      lastName,
      userId,
      role: filteredRoles[0].name,
      roleDetail: filteredRoles[0],
      isHF4User,
      reportPrivileges,
      tenantId,
      country: isReportSuperAdmin ? null : country,
      suiteAccess,
      formDataId: organizations[0]?.formDataId,
      assignedFacility: organizations[0] || {},
      // if there is any oldAppTypes then persist that, otherwise don't store appTypes for report super admin
      appTypes: isReportSuperAdmin && !oldAppTypes ? [] : appTypes || country?.appTypes || oldAppTypes || []
    };
    if ((payload.appTypes || []).length) {
      localStorageService.setItem(APP_TYPE_NAME, `${JSON.stringify(payload.appTypes)}`);
    }
    yield put(userActions.fetchLoggedInUserSuccess(payload));
  } catch (e) {
    yield put(userActions.fetchLoggedInUserFail());
  }
}

export function* fetchDefaultRoles(): SagaIterator {
  try {
    const { data: roles } = yield call(userService.getDefaultRoles);
    yield put(userActions.fetchDefaultRoleSuccess(roles));
  } catch (e) {
    yield put(userActions.fetchDefaultRoleFailure());
  }
}

export function* fetchCHWUserSaga({
  skip,
  limit,
  tenantId,
  successCb,
  failureCb
}: IFetchCHWUsersRequest): SagaIterator {
  try {
    const {
      data: { entityList: userLists }
    } = yield call(userService.fetchCHWUsersAPI, { skip, limit, tenantId });
    yield put(userActions.fetchCHWUsersSuccess(userLists));
    successCb?.(userLists);
  } catch (e) {
    failureCb?.();
    yield put(userActions.fetchCHWUsersFailure());
  }
}

/*
  Starts worker saga on latest dispatched `LOGIN_REQUEST` action.
  Allows concurrent increments.
*/
function* userSaga() {
  yield all([takeLatest(USERTYPES.LOGOUT_REQUEST, logout)]);
  yield all([takeLatest(USERTYPES.FETCH_TIMEZONE_LIST_REQUEST, fetchTimezoneList)]);
  yield takeLatest(USERTYPES.FETCH_LOGGED_IN_USER_REQUEST, fetchLoggedInUser);
  yield all([takeLatest(USERTYPES.FETCH_DEFAULT_ROLE, fetchDefaultRoles)]);
  yield all([takeLatest(USERTYPES.FETCH_CHW_USER_LIST_REQUEST, fetchCHWUserSaga)]);
}

export default userSaga;

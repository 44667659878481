import axios from 'axios';
import { IFetchCHWUsersRequest } from '../store/user/types';

export const logout = () =>
  axios({
    method: 'GET',
    url: 'auth-service/logout'
  });

export const fetchTimezoneList = () =>
  axios({
    method: 'GET',
    url: '/forms/timezone?$limit=null'
  });

export const fetchUserRoles = (token: string) =>
  axios({
    method: 'get',
    url: `user-service/user/get-user-roles?$token=${token}`
  });

export const fetchLoggedInUser = () =>
  axios({
    method: 'POST',
    url: `user-service/user/profile`
  });

export const getDefaultRoles = () =>
  axios({
    method: 'get',
    url: 'user-service/user/roles'
  });

export const fetchCHWUsersAPI = (data: Omit<IFetchCHWUsersRequest, 'type' | 'successCb' | 'failureCb'>) =>
  axios({
    method: 'post',
    url: 'cfr-service/user/supervisors-chw-list',
    data
  });

import React, { useEffect, useState, useRef } from 'react';
import styles from './AssessmentReport.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import ERRORS from '../../constants/error';
import APPCONSTANTS from '../../constants/appConstants';
import DetailCard from '../../components/detailCard/DetailCard';
import CustomTable from '../../components/customtable/CustomTable';
import toastCenter from '../../utils/toastCenter';
import Loader from '../../components/loader/Loader';
import FilterLayout from '../../components/filter/FilterLayout';
import { assessmentListSelector, loadingSelector, totalSelector } from '../../store/report/selectors';
import { firstNameSelector, lastNameSelector, roleSelector } from '../../store/user/selectors';
import { fetchAssessmentListRequest } from '../../store/report/actions';
import { downloadAssessmentListReport } from '../../services/reportAPI';
import { getPageLocationWithParams, trackGoogleAnalyticsEvent } from '../../utils/commonUtils';

interface IMatchParams {
  siteId: string;
  siteName: string;
}

const AssessmentReport = (props: IMatchParams): React.ReactElement => {
  const fromDatePickerRef = useRef<DatePicker>(null);
  const toDatePickerRef = useRef<DatePicker>(null);
  const [listParams, setListReqParams] = useState({
    page: APPCONSTANTS.NUMBER.ONE,
    rowsPerPage: APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE,
    searchTerm: ''
  });

  const [fromDate, setFromDate] = useState<Date | null | undefined>(undefined);
  const [toDate, setToDate] = useState<Date | null | undefined>(undefined);
  const [gender, setGender] = useState<Array<null | string>>([]);
  const [HTNDiagnosis, setHTNDiagnosis] = useState(false);
  const [DBMDiagnosis, setDBMDiagnosis] = useState(false);
  const [ProvisionalDiagnosis, setProvisionalDiagnosis] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const role: any = useSelector(roleSelector);
  const firstName: string = useSelector(firstNameSelector) || '';
  const lastName: string = useSelector(lastNameSelector) || '';

  const dispatch = useDispatch();

  const assessmentList = useSelector(assessmentListSelector);
  const loading = useSelector(loadingSelector);
  const assessmentListCount = useSelector(totalSelector);

  const { siteId, siteName } = useParams<IMatchParams>();

  const prevDateFetchValue = useRef<any>({
    fromDate: null,
    toDate: null
  });

  useEffect(() => {
    if (!fromDate) {
      toDatePickerRef.current?.props.onChange(null, undefined);
      setToDate(null);
    }
    if ((fromDate && !toDate) || (fromDate && toDate)) {
      toDatePickerRef.current?.props.minDate?.setDate(fromDate.getDate());
      toDatePickerRef.current?.props.minDate?.setMonth(fromDate.getMonth());
      toDatePickerRef.current?.props.minDate?.setFullYear(fromDate.getFullYear());
    }
    if (toDate) {
      trackGoogleAnalyticsEvent(
        APPCONSTANTS.USER_ACTIONS.FILTERS.DATE_FILTER,
        APPCONSTANTS.REPORT_LIST.ASSESSMENT_REPORT,
        getPageLocationWithParams(),
        { username: `${firstName} ${lastName}`, role: role.label }
      );
      fromDatePickerRef.current?.props.maxDate?.setDate(toDate.getDate());
      fromDatePickerRef.current?.props.maxDate?.setMonth(toDate.getMonth());
      fromDatePickerRef.current?.props.maxDate?.setFullYear(toDate.getFullYear());
    }
  }, [toDatePickerRef, fromDate, toDate, loading, exportLoading, firstName, lastName, role.label]);

  const reqPayload = (newParams: any) => {
    const reqData = {
      healthFacilityId: Number(siteId),
      skip: (newParams.page - APPCONSTANTS.NUMBER.ONE) * newParams.rowsPerPage,
      limit: newParams.rowsPerPage,
      failureCb: (e: Error) => {
        if (e.message === ERRORS.NETWORK_ERROR.message) {
          toastCenter.error(APPCONSTANTS.NETWORK_ERROR, APPCONSTANTS.CONNECTION_LOST);
        } else {
          toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.ASSESSMENT_LIST_FETCH_ERROR);
        }
      }
    };
    if (fromDate && toDate) {
      Object.assign(reqData, {
        fromDate: moment(fromDate).format(APPCONSTANTS.YEAR_MONTH_DATE_FORMAT),
        toDate: moment(toDate).format(APPCONSTANTS.YEAR_MONTH_DATE_FORMAT)
      });
    }
    if (gender.length) {
      Object.assign(reqData, { genders: gender });
    }
    if (HTNDiagnosis) {
      Object.assign(reqData, { htnDiagnosis: HTNDiagnosis });
    }
    if (DBMDiagnosis) {
      Object.assign(reqData, { dbmDiagnosis: DBMDiagnosis });
    }
    if (ProvisionalDiagnosis) {
      Object.assign(reqData, { provisionalDiagnosis: ProvisionalDiagnosis });
    }
    return reqData;
  };

  const fetchList = (newParams: any) => {
    dispatch(fetchAssessmentListRequest({ payload: reqPayload(newParams) }));
    setListReqParams(newParams);
  };

  useEffect(() => {
    trackGoogleAnalyticsEvent(
      APPCONSTANTS.USER_ACTIONS.REPORT_VIEW,
      APPCONSTANTS.REPORT_LIST.ASSESSMENT_REPORT,
      getPageLocationWithParams(),
      { username: `${firstName} ${lastName}`, role: role.label }
    );
    const newParams = {
      ...listParams,
      page: 1
    };
    fetchList(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, gender, HTNDiagnosis, DBMDiagnosis, ProvisionalDiagnosis, dispatch]);

  useEffect(() => {
    const formatDate = (date: any) => (date ? moment(date).format(APPCONSTANTS.YEAR_MONTH_DATE_FORMAT) : null);
    const prevFromDate = formatDate(prevDateFetchValue.current?.fromDate);
    const prevToDate = formatDate(prevDateFetchValue.current?.toDate);
    const currFromDate = formatDate(fromDate);
    const currToDate = formatDate(toDate);
    const valueChanged = () => {
      return prevFromDate !== currFromDate || prevToDate !== currToDate;
    };
    if ((fromDate && toDate && valueChanged()) || (fromDate === null && toDate === null && valueChanged())) {
      prevDateFetchValue.current = {
        fromDate: currFromDate,
        toDate: currToDate
      };
      const newParams = {
        ...listParams,
        page: 1
      };
      fetchList(newParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, dispatch]);

  const handleSearch = (searchString: string) => {
    const newParams = {
      ...listParams,
      page: APPCONSTANTS.NUMBER.ONE,
      searchTerm: searchString
    };
    fetchList(newParams);
  };

  const handlePage = (pageNo: number, rowsPerPage: number | undefined = APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE) => {
    const newParams = {
      ...listParams,
      page: pageNo,
      rowsPerPage
    };
    fetchList(newParams);
  };

  const handleGenderOnChange = (event: React.BaseSyntheticEvent) => {
    if (event.target.checked) {
      trackGoogleAnalyticsEvent(
        APPCONSTANTS.USER_ACTIONS.FILTERS.GENDER_FILTER,
        APPCONSTANTS.REPORT_LIST.ASSESSMENT_REPORT,
        getPageLocationWithParams(),
        { username: `${firstName} ${lastName}`, role: role.label }
      );
      setGender((values) => [...values, event.target.value]);
    } else {
      const index = gender?.indexOf(event.target.value);
      const updatedValue = gender;
      updatedValue.splice(index, 1);
      setGender((values) => [...updatedValue]);
    }
  };

  const exportAssessmentList = async () => {
    try {
      setExportLoading(true);
      const payLoad = {
        healthFacilityId: siteId,
        siteName,
        limit: assessmentListCount
      };
      if (fromDate && toDate) {
        Object.assign(payLoad, {
          fromDate: moment(fromDate).format(APPCONSTANTS.YEAR_MONTH_DATE_FORMAT),
          toDate: moment(toDate).format(APPCONSTANTS.YEAR_MONTH_DATE_FORMAT)
        });
      }
      if (gender.length) {
        Object.assign(payLoad, { genders: gender });
      }
      if (HTNDiagnosis) {
        Object.assign(payLoad, { htnDiagnosis: HTNDiagnosis });
      }
      if (DBMDiagnosis) {
        Object.assign(payLoad, { dbmDiagnosis: DBMDiagnosis });
      }
      if (ProvisionalDiagnosis) {
        Object.assign(payLoad, { provisionalDiagnosis: ProvisionalDiagnosis });
      }
      await downloadAssessmentListReport(payLoad);
      trackGoogleAnalyticsEvent(
        APPCONSTANTS.USER_ACTIONS.REPORT_DOWNLOAD,
        APPCONSTANTS.REPORT_LIST.ASSESSMENT_REPORT,
        getPageLocationWithParams(),
        { username: `${firstName} ${lastName}`, role: role.label }
      );
      setExportLoading(false);
      toastCenter.success(APPCONSTANTS.SUCCESS, APPCONSTANTS.REPORT_DOWNLOAD_SUCCESS);
    } catch {
      setExportLoading(false);
      toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.DOWNLOAD_FAIL_MSG);
    }
  };
  return (
    <>
      {loading && <Loader />}
      {exportLoading && <Loader />}
      <div className='row'>
        <FilterLayout
          fromDateSelector={(date: Date) => {
            setFromDate(date);
          }}
          toDateSelector={(date: Date) => setToDate(date)}
          handleGenderOnChange={(event: React.BaseSyntheticEvent) => handleGenderOnChange(event)}
          HTNOnChange={(event: React.BaseSyntheticEvent) => {
            trackGoogleAnalyticsEvent(
              APPCONSTANTS.USER_ACTIONS.FILTERS.DIAGNOSIS_FILTER,
              APPCONSTANTS.REPORT_LIST.ASSESSMENT_REPORT,
              getPageLocationWithParams(),
              { username: `${firstName} ${lastName}`, role: role.label }
            );
            setHTNDiagnosis(event.target.checked);
          }}
          DBMOnChange={(event: React.BaseSyntheticEvent) => {
            trackGoogleAnalyticsEvent(
              APPCONSTANTS.USER_ACTIONS.FILTERS.DIAGNOSIS_FILTER,
              APPCONSTANTS.REPORT_LIST.ASSESSMENT_REPORT,
              getPageLocationWithParams(),
              { username: `${firstName} ${lastName}`, role: role.label }
            );
            setDBMDiagnosis(event.target.checked);
          }}
          ProvisionalOnChange={(event: React.BaseSyntheticEvent) => {
            trackGoogleAnalyticsEvent(
              APPCONSTANTS.USER_ACTIONS.FILTERS.DIAGNOSIS_FILTER,
              APPCONSTANTS.REPORT_LIST.ASSESSMENT_REPORT,
              getPageLocationWithParams(),
              { username: `${firstName} ${lastName}`, role: role.label }
            );
            setProvisionalDiagnosis(event.target.checked);
          }}
          fromDate={fromDate}
          fromDatePickerRef={fromDatePickerRef}
          toDatePickerRef={toDatePickerRef}
          showProvisionalDiagnosis={true}
        />
        <div className={`${styles.overrideWidth} 'col'`}>
          <DetailCard
            header='Assessment Report'
            isSearch={false}
            onSearch={handleSearch}
            buttonLabel='Export'
            onButtonClick={() => exportAssessmentList()}
            isDownload={true}
            isDisabled={assessmentList.length === 0}
          >
            <CustomTable
              rowData={assessmentList}
              columnsDef={[
                {
                  id: 1,
                  name: 'firstName',
                  label: 'Name',
                  width: '130px'
                },
                {
                  id: 2,
                  name: 'gender',
                  label: 'Gender',
                  width: '110px'
                },
                {
                  id: 3,
                  name: 'phoneNumber',
                  label: 'Phone Number',
                  class: 'numeric',
                  width: '130px'
                },
                {
                  id: 4,
                  name: 'provisionalDiagnosis',
                  label: 'Provisional Diagnosis',
                  width: '110px',
                  cellFormatter: (data: any) => {
                    if (data?.provisionalDiagnosis == null) {
                      return '';
                    } else {
                      return String(data.provisionalDiagnosis);
                    }
                  }
                },
                {
                  id: 5,
                  name: 'confirmDiagnosis',
                  label: 'Confirm Diagnosis',
                  width: '240px',
                  cellFormatter: (data: any) => {
                    if (data?.confirmDiagnosis == null) {
                      return '';
                    } else {
                      return String(data.confirmDiagnosis);
                    }
                  }
                },
                {
                  id: 6,
                  name: 'lastBpAssessmentDate',
                  label: 'Last BP assessment date',
                  width: '130px',
                  cellFormatter: (data: any) => {
                    if (data?.lastBpAssessmentDate) {
                      return moment(data.lastBpAssessmentDate).format(APPCONSTANTS.DATE_MONTH_YEAR_FORMAT);
                    } else {
                      return '';
                    }
                  }
                },
                {
                  id: 7,
                  name: 'lastBgAssessmentDate',
                  label: 'Last BG assessment date',
                  width: '130px',
                  cellFormatter: (data: any) => {
                    if (data?.lastBgAssessmentDate) {
                      return moment(data.lastBgAssessmentDate).format(APPCONSTANTS.DATE_MONTH_YEAR_FORMAT);
                    } else {
                      return '';
                    }
                  }
                },
                {
                  id: 8,
                  name: 'nextBpAssesmentDate',
                  label: 'Next BP Assessment Date',
                  width: '130px',
                  cellFormatter: (data: any) => {
                    if (data?.nextBpAssesmentDate) {
                      return moment(data.nextBpAssesmentDate).format(APPCONSTANTS.DATE_MONTH_YEAR_FORMAT);
                    } else {
                      return '';
                    }
                  }
                },
                {
                  id: 9,
                  name: 'nextBgAssesmentDate',
                  label: 'Next BG Assessment Date',
                  width: '130px',
                  cellFormatter: (data: any) => {
                    if (data?.nextBgAssesmentDate) {
                      return moment(data.nextBgAssesmentDate).format(APPCONSTANTS.DATE_MONTH_YEAR_FORMAT);
                    } else {
                      return '';
                    }
                  }
                },
                {
                  id: 10,
                  name: 'assessmentCategory',
                  label: 'Assessment Category',
                  width: '130px'
                }
              ]}
              isDelete={false}
              isEdit={false}
              isView={false}
              isDownload={false}
              page={listParams.page}
              rowsPerPage={listParams.rowsPerPage}
              count={assessmentListCount}
              handlePageChange={handlePage}
            />
          </DetailCard>
        </div>
      </div>
    </>
  );
};

export default AssessmentReport;

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { roleSelector } from '../store/user/selectors';
import APPCONSTANTS from '../constants/appConstants';
import { getRegionDetailSelector } from '../store/region/selectors';
import { PROTECTED_ROUTES } from '../constants/route';

const useUserAdmin = () => {
  const role = useSelector(roleSelector);
  const regionDetails = useSelector(getRegionDetailSelector);
  const { REPORT_SUPER_ADMIN, REPORT_ADMIN } = APPCONSTANTS.ROLES;
  const { id: regionId, name: regionName } = regionDetails;

  const isAF = useMemo(() => {
    return Boolean(role === REPORT_SUPER_ADMIN || (role === REPORT_ADMIN && regionDetails.id));
  }, [role, REPORT_SUPER_ADMIN, REPORT_ADMIN, regionDetails.id]);

  const HomeUrl = useMemo(() => {
    return role === REPORT_SUPER_ADMIN || (role === REPORT_ADMIN ? !regionDetails.id : false)
      ? PROTECTED_ROUTES.regionDashboard
      : PROTECTED_ROUTES.siteByRegion.replace(':regionId', String(regionId)).replace(':regionName', regionName);
  }, [REPORT_ADMIN, REPORT_SUPER_ADMIN, regionDetails.id, regionId, regionName, role]);

  return useMemo(
    () => ({
      isUserAdmin: role === REPORT_SUPER_ADMIN || (role === REPORT_ADMIN ? !regionDetails.id : false),
      regionDashboardRoles: isAF ? [REPORT_SUPER_ADMIN] : [REPORT_ADMIN],
      HomeUrl
    }),
    [HomeUrl, REPORT_ADMIN, REPORT_SUPER_ADMIN, isAF, regionDetails.id, role]
  );
};

export default useUserAdmin;

export const PROTECTED_ROUTES = {
  regionDashboard: '/region',
  site: '/region/:regionId/:regionName/site',
  siteByRegion: '/region/:regionId/:regionName',
  reportList: '/region/:regionId/:regionName/site/:siteId/:siteName',

  dailyReport: '/region/:regionId/:regionName/site/:siteId/:siteName/daily-report',
  permanentReport: '/region/:regionId/:regionName/site/:siteId/:siteName/permanent-report',
  MOHReport: '/region/:regionId/:regionName/site/:siteId/:siteName/MOH-report',
  prescriptionReport: '/region/:regionId/:regionName/site/:siteId/:siteName/prescription-medication',
  screenedReport: '/region/:regionId/:regionName/site/:siteId/:siteName/screened-patient-report',
  enrolledPatientReport: '/region/:regionId/:regionName/site/:siteId/:siteName/enrolled-patient-report',
  assessmentReport: '/region/:regionId/:regionName/site/:siteId/:siteName/assessment-report',
  userPerformance: '/region/:regionId/:regionName/site/:siteId/:siteName/user-performance',
  patientStatusReport: '/region/:regionId/:regionName/site/:siteId/:siteName/patient-status-report',

  fileDownload: '/region/:regionId/:regionName/fileDownload',
  reviewDuplicates: '/region/:regionId/:regionName/site/:siteId/:siteName/duplicate-profiles/:groupId',

  HFReport: '/region/:regionId/:regionName/site/:siteId/:siteName/:reportType',
  manageUserRoles: '/manage-user-roles',
  home: '/home'
};

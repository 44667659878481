import APPCONSTANTS from '../constants/appConstants';
import ReactGA from 'react-ga4';
import CryptoJS from 'crypto-js';

const getEncryptionKey = () => {
  return CryptoJS.PBKDF2(process.env.REACT_APP_CRYPTR_SECRET_KEY as string, APPCONSTANTS.ENCRYPTION.SALT, {
    keySize: APPCONSTANTS.ENCRYPTION.KEYLEN / 32,
    iterations: APPCONSTANTS.ENCRYPTION.ITERATION
  });
};

export const decryptData = (password: string) => {
  const key = getEncryptionKey();
  const iv = CryptoJS.enc.Utf8.parse(APPCONSTANTS.ENCRYPTION.IV);
  return CryptoJS.AES.decrypt(password, CryptoJS.enc.Utf8.parse(key as any), { iv }).toString(CryptoJS.enc.Utf8);
};

export const encryptData = (value: string) => {
  const key = getEncryptionKey();
  const iv = CryptoJS.enc.Utf8.parse(APPCONSTANTS.ENCRYPTION.IV);
  return CryptoJS.AES.encrypt(value, CryptoJS.enc.Utf8.parse(key as any), { iv }).toString();
};

/**
 * Appends zero before given number if number of digitd is less that minimumIntegerDigits
 * @param num
 * @param minimumIntegerDigits
 * @returns {string}
 */
export const appendZeroBefore = (num: number, minimumIntegerDigits: number): string =>
  (Number(num) || 0).toLocaleString('en-US', {
    minimumIntegerDigits,
    useGrouping: false
  });

/**
 * A utility function to stop the event from propogating up on DOM
 * @param e
 */
export const stopPropogation = (e: React.BaseSyntheticEvent) => {
  try {
    e.stopPropagation();
  } catch (error) {
    console.error(error);
  }
};

/**
 * A utility function to check difference of two arrays
 */
export const differenceOfArray = (ary1: any, ary2: any) => ary1.filter((value: any) => !ary2.includes(value));

/**
 * A utility function to convert camelCase to Sentence Case
 */
export const getSentenceCase = (camelCase: string) => {
  const result = camelCase.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const parseURLData = (url: string) => {
  // const regex = /\/region\/(\d+)\/([^/]+)(\/site\/(\d+)\/([^/]+))?/;
  const regex = /\/region\/(\d+)\/([^/]+)(\/site\/(\d+)\/([^/]+))(\/([^/]+))?/;

  const matches = url.match(regex);

  if (!matches) {
    return {
      countryId: undefined,
      regionName: undefined,
      siteId: undefined,
      siteName: undefined,
      reportType: ''
    };
  }

  const countryId = matches[1] ? parseInt(matches[1], 10) : undefined;
  const regionName = matches[2];
  const siteId = matches[4] ? parseInt(matches[4], 10) : undefined;
  const siteName = matches[5];
  const reportType = matches[7];

  return {
    countryId: countryId?.toString(),
    regionName,
    siteId: siteId?.toString(),
    siteName,
    reportType
  };
};

export const reportNameFormat = (reportType?: string) => {
  const firstPart = reportType?.slice(0, reportType?.indexOf('-')).toUpperCase();
  return `${firstPart} ${reportType?.slice(reportType?.indexOf('-') + 1)}`;
};

export const reportSizeFormat = (reportName: string) => {
  const index = [];
  const size: { [key: string]: number } = {
    HF1: 7,
    HF2: 7,
    HF3: 7,
    HF4: 7.5
  };
  const height: { [key: string]: number } = {
    HF1: 25,
    HF2: 30,
    HF3: 25,
    HF4: 30
  };
  if (reportName === 'HF2') {
    index.push({ 0: 80 });
  } else if (reportName === 'HF4') {
    index.push({ 0: 50 });
  }
  return {
    charPixel: size[reportName] || 7,
    index,
    rowHeight: height[reportName] || 25
  };
};

export const trackGoogleAnalyticsEvent = (category: string, eventName: string, route: string, data: any) => {
  const eventParams = {
    category,
    route,
    ...data
  };
  ReactGA.event(eventName, eventParams);
};

export const getPageLocationWithParams = () => {
  return window.location.pathname + window.location.search;
};

export const jsonParse = (value: any) => {
  if (value) {
    try {
      return JSON.parse(value);
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
};

export const isKHISLastDateExceeded = () => {
  const now = new Date();
  const dateToday = now.getDate();
  return dateToday > APPCONSTANTS.KHIS_LAST_DATE_TO_SUBMIT ? true : false;
};

export const getIsUserFacilityReportAdmin = (role: any) => {
  return APPCONSTANTS.SEND_TO_KHIS_ROLES_ACCESS.includes(role);
};

import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import APPCONSTANTS, { APP_TYPE_NAME } from '../../constants/appConstants';
import ERRORS from '../../constants/error';
import sessionStorageServices from '../../global/sessionStorageServices';
import { useLoadMorePagination } from '../../hooks/pagination';
import { clearRegionDetail, fetchRegionsRequest, setRegionDetail } from '../../store/region/actions';
import {
  getRegionsCountSelector,
  getRegionsLoadingMoreSelector,
  getRegionsLoadingSelector,
  getRegionsSelector
} from '../../store/region/selectors';
import toastCenter from '../../utils/toastCenter';

import { PROTECTED_ROUTES } from '../../constants/route';
import { default as localStorageServices } from '../../global/localStorageServices';
import { fetchCountryName, setAppType, setIsKenya } from '../../store/user/actions';
import styles from './Region.module.scss';

/**
 * Lists all the regions
 * Provides search feature
 * Provided the links to navigate to creation page of super admin and region
 * @returns {React.ReactElement}
 */
const Region = (): React.ReactElement => {
  const dispatch = useDispatch();
  const regions = useSelector(getRegionsSelector);
  const regionsCount = useSelector(getRegionsCountSelector);
  const loading = useSelector(getRegionsLoadingSelector);
  const loadingMore = useSelector(getRegionsLoadingMoreSelector);
  const { isLastPage, loadMore } = useLoadMorePagination({
    total: regionsCount,
    itemsPerPage: APPCONSTANTS.REGIONS_PER_PAGE,
    onLoadMore: ({ skip, limit, onFail }) => {
      dispatch(
        fetchRegionsRequest({
          skip,
          limit,
          isLoadMore: true,
          failureCb: (e) => {
            onFail();
            if (e.message === ERRORS.NETWORK_ERROR.message) {
              toastCenter.error(APPCONSTANTS.NETWORK_ERROR, APPCONSTANTS.CONNECTION_LOST);
            } else {
              toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.REGION_FETCH_ERROR);
            }
          }
        })
      );
    }
  });

  useEffect(() => {
    dispatch(
      fetchRegionsRequest({
        skip: 0,
        limit: APPCONSTANTS.REGIONS_PER_PAGE,
        failureCb: (e) => {
          if (e.message === ERRORS.NETWORK_ERROR.message) {
            toastCenter.error(APPCONSTANTS.NETWORK_ERROR, APPCONSTANTS.CONNECTION_LOST);
          } else {
            toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.REGION_FETCH_ERROR);
          }
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(setIsKenya(false));
    dispatch(setAppType([]));
    dispatch(clearRegionDetail());
    dispatch(fetchCountryName());
    dispatch(setIsKenya(false));
    localStorageServices.deleteItem(APP_TYPE_NAME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const regionParsedData = useMemo(
    () =>
      regions.map(({ name, id, code, appTypes = [] }) => ({
        title: name,
        name,
        code,
        onNavigate: () => {
          sessionStorageServices.setItem(APPCONSTANTS.REGION_ID, id);
          sessionStorageServices.setItem(APPCONSTANTS.COUNTRY_NAME, name);
          if (name.toLowerCase() === APPCONSTANTS.KENYA) {
            sessionStorageServices.setItem(APPCONSTANTS.IS_KENYA, true);
            dispatch(setIsKenya(true));
          } else {
            sessionStorageServices.deleteItem(APPCONSTANTS.IS_KENYA);
            dispatch(setIsKenya(false));
          }
          dispatch(setRegionDetail({ name, id }));
          localStorageServices.setItem(APP_TYPE_NAME, `${JSON.stringify(appTypes)}`);
          dispatch(setAppType(appTypes));
        },
        route: PROTECTED_ROUTES.siteByRegion.replace(':regionId', id.toString()).replace(':regionName', name)
      })),
    [regions, dispatch]
  );

  const loadRegionIcon = (name: string) => {
    try {
      return require(`../../assets/images/${name}.svg`).default;
    } catch (err) {
      return require(`../../assets/images/no-icon.svg`).default;
    }
  };

  const noRegionsAvailable = !regionParsedData.length && !loading;

  return (
    <div className={`position-relative ${styles.regionContainer}`}>
      <div className={styles.regionsList}>
        <div className='row'>
          {!noRegionsAvailable &&
            regionParsedData.map((data) => (
              <div className='col-3 p-1 width-20' key={data.name}>
                <div className={styles.cards}>
                  <Link
                    to={data.route}
                    onClick={(event: React.MouseEvent) => {
                      data.onNavigate();
                    }}
                  >
                    <div className='row p-2'>
                      <img src={loadRegionIcon(data.name)} alt={`${data.name}_logo`} width={60} height={70} />
                    </div>
                    <div className={`row ${styles.cardText} p-1`}>
                      <p>{data.name}</p>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          {noRegionsAvailable && (
            <div className={`col-12 text-center mt-1 py-3dot75 ${styles.noData}`}>
              <div className='fw-bold highlight-text'>No regions available</div>
            </div>
          )}
          {Boolean(regionParsedData.length) && !isLastPage && !loadingMore && (
            <div className='col-12 text-center mt-3dot75'>
              <button className={`btn primary-btn ${styles.loadMoreBtn}`} onClick={loadMore}>
                Load More<b className={styles.ellipsis}>...</b>
              </button>
            </div>
          )}
          {loadingMore || loading ? (
            <div className={loadingMore ? `${styles.loaderWrapper} mt-3dot75` : ''}>
              <Loader isFullScreen={!loadingMore} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Region;

import Checkbox from '../formComponent/Checkbox';
import CustomDatePicker from './CustomDatePicker';
import styles from '../../containers/report/ReportList.module.scss';
import React, { useState } from 'react';
import APPCONSTANTS, { MOBILE_USERS } from '../../constants/appConstants';
import ResetIcon from '../../assets/images/reset.svg';
import CustomTooltip from '../tooltip';

interface IFilterLayoutProps {
  fromDateSelector?: (date: Date) => void;
  toDateSelector?: (date: Date) => void;
  handleGenderOnChange?: (event: React.BaseSyntheticEvent) => void;
  HTNOnChange?: (event: React.BaseSyntheticEvent) => void;
  DBMOnChange?: (event: React.BaseSyntheticEvent) => void;
  ProvisionalOnChange?: (event: React.BaseSyntheticEvent) => void;
  refferedAssessmentOnChange?: (event: React.BaseSyntheticEvent) => void;
  handleRoleOnChange?: (event: React.BaseSyntheticEvent) => void;
  fromDate?: Date | null;
  fromDatePickerRef?: any;
  toDatePickerRef?: any;
  showProvisionalDiagnosis?: boolean;
  showCommonFilters?: boolean;
  showUserPerformanceFilters?: boolean;
}

const mobileUserRoles = Object.values(MOBILE_USERS);

const FilterLayout = ({
  fromDateSelector,
  toDateSelector,
  handleGenderOnChange,
  HTNOnChange,
  DBMOnChange,
  ProvisionalOnChange,
  refferedAssessmentOnChange,
  handleRoleOnChange,
  fromDate,
  fromDatePickerRef,
  toDatePickerRef,
  showProvisionalDiagnosis,
  showCommonFilters = true,
  showUserPerformanceFilters = false
}: IFilterLayoutProps): React.ReactElement => {
  const [isResetFilterEnabled, setResetFilterEnabled] = useState(false);
  return (
    <div className='col-auto'>
      <div className={`${styles.sideMenu} ${styles.customSidemenu}`}>
        <div className={`bg-transparent ${styles.sideMenuHeader} py-dot625`}>
          <div className={styles.headerLabel}>Filters</div>
          <div
            className={styles.resetIcon}
            onClick={() => {
              setResetFilterEnabled(true);
              fromDatePickerRef.current?.props.onChange(null, undefined);
            }}
          >
            <CustomTooltip title='Reset'>
              <img src={ResetIcon} alt='reset-icon' />
            </CustomTooltip>
          </div>
        </div>
        <div className='py-dot625'>
          <label>Start Date</label>
          <div>
            <CustomDatePicker pickerRef={fromDatePickerRef} dayReducer={0} onDateSelect={fromDateSelector} />
          </div>
        </div>
        <div className={fromDate ? 'py-dot625' : `${styles.disable} py-dot625`}>
          <label>End Date</label>
          <div>
            <CustomDatePicker pickerRef={toDatePickerRef} dayReducer={0} onDateSelect={toDateSelector} />
          </div>
        </div>
        {showCommonFilters && (
          <div>
            <div className='py-dot625'>
              <label>Gender</label>
              <div>
                {APPCONSTANTS.GENDER.map((value) => (
                  <Checkbox
                    key={value.name}
                    label={value.label}
                    name={value.name}
                    onChange={handleGenderOnChange}
                    isResetFilterEnabled={isResetFilterEnabled}
                    setResetFilterEnabled={setResetFilterEnabled}
                  />
                ))}
              </div>
            </div>
            <div className='py-dot625'>
              <label>Diagnosis</label>
              <div>
                <Checkbox
                  label='HTN'
                  onChange={HTNOnChange}
                  isResetFilterEnabled={isResetFilterEnabled}
                  setResetFilterEnabled={setResetFilterEnabled}
                />
                <Checkbox
                  label='DBM'
                  onChange={DBMOnChange}
                  isResetFilterEnabled={isResetFilterEnabled}
                  setResetFilterEnabled={setResetFilterEnabled}
                />
                {showProvisionalDiagnosis && (
                  <Checkbox
                    label='PROVISIONAL'
                    onChange={ProvisionalOnChange}
                    isResetFilterEnabled={isResetFilterEnabled}
                    setResetFilterEnabled={setResetFilterEnabled}
                  />
                )}
              </div>
            </div>
            {refferedAssessmentOnChange && (
              <div className='py-dot625'>
                <label>Assessment</label>
                <div>
                  <Checkbox
                    label='Referred Assessment'
                    onChange={refferedAssessmentOnChange}
                    isResetFilterEnabled={isResetFilterEnabled}
                    setResetFilterEnabled={setResetFilterEnabled}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {showUserPerformanceFilters && (
          <div className='py-dot625'>
            <label>User Roles</label>
            <div>
              {mobileUserRoles.map((value) => (
                <div className='col-12' key={value.label}>
                  <Checkbox
                    label={value.label}
                    name={value.role}
                    onChange={handleRoleOnChange}
                    isResetFilterEnabled={isResetFilterEnabled}
                    setResetFilterEnabled={setResetFilterEnabled}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterLayout;

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const FETCH_TIMEZONE_LIST_REQUEST = 'FETCH_TIMEZONE_LIST_REQUEST';
export const FETCH_TIMEZONE_LIST_SUCCESS = 'FETCH_TIMEZONE_LIST_SUCCESS';
export const FETCH_TIMEZONE_LIST_FAILURE = 'FETCH_TIMEZONE_LIST_FAILURE';

export const FETCH_LOGGED_IN_USER_REQUEST = 'FETCH_LOGGED_IN_USER_REQUEST';
export const FETCH_LOGGED_IN_USER_SUCCESS = 'FETCH_LOGGED_IN_USER_SUCCESS';
export const FETCH_LOGGED_IN_USER_FAILURE = 'FETCH_LOGGED_IN_USER_FAILURE';

export const SESSION_TIMEDOUT = 'SESSION_TIMEDOUT';
export const RESET_STORE = 'RESET_STORE';
export const SET_HF4_USER_STATUS = 'SET_HF4_USER_STATUS';

export const FETCH_DEFAULT_ROLE = 'FETCH_DEFAULT_ROLE';
export const FETCH_DEFAULT_ROLE_SUCCESS = 'FETCH_DEFAULT_ROLE_SUCCESS';
export const FETCH_DEFAULT_ROLE_FAILURE = 'FETCH_DEFAULT_ROLE_FAILURE';

export const FETCH_COUNTRY_NAME = 'FETCH_COUNTRY_NAME';
export const CLEAR_COUNTRY_NAME = 'CLEAR_COUNTRY_NAME';
export const CLEAR_COUNTRY_ID = 'CLEAR_COUNTRY_ID';

export const FETCH_CHW_USER_LIST_REQUEST = 'FETCH_CHW_USER_LIST_REQUEST';
export const FETCH_CHW_USER_LIST_SUCCESS = 'FETCH_CHW_USER_LIST_SUCCESS';
export const FETCH_CHW_USER_LIST_FAILURE = 'FETCH_CHW_USER_LIST_FAILURE';

export const SET_APP_TYPE = 'SET_APP_TYPE';
export const CLEAR_APP_TYPE = 'CLEAR_APP_TYPE';

export const SET_IS_KENYA = 'SET_IS_KENYA';
export const SET_REPORT_PRIVILEGES = 'SET_REPORT_PRIVILEGES';
